.link-panel
{
    position: fixed;
    margin: 0 16px 0 8px !important;
    max-width: 200px;

    ion-list
    {
        ion-item
        {
            &.down-line
            {
                border-bottom: 1px solid var(--ion-color-light-shade);
                ion-label{
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #323233;
                    padding-left: 16px !important;
                }
            }

           &.clickable-item
            {
                cursor: pointer;
            }
            
            &.clickable-item:hover{
            
                ion-label
                {
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: var(--ion-color-tertiary) !important;
                }
              
            }
        }

        .dot {
            display: none;
        }


        .selected-item
        {
            .dot {
                height: 8px;
                width: 8px;
                // margin: 0 1em 0 0;
                background-color: var(--ion-color-tertiary) !important;
                border-radius: 50%;
                display: inline-block;
                position: absolute !important;
                margin-left: -16px !important;
                margin-top: 5px !important;
              }
        
            ion-label
            {
                list-style-type:circle;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: var(--ion-color-tertiary) !important;
            }
        }
    }
}

.center-scroll-panel
{
    margin-left: 150px !important;
    width: calc(100% - 140px) !important;
}

// @media screen and (max-width: 768px) {

//     .link-panel
//     {
//         top: 94px !important;
//     }
// }

@media screen and (max-width: 1024px) {

    .link-panel
    {
        max-width: 100%;
        width: 100%;
        position: fixed;
        top: 57px;
        left: 0;
        right: 0;
        margin: 0;
        z-index: 999;
        border-bottom: 1px solid var(--ion-color-light-shade);
		overflow: hidden;
		overflow-x: auto;
		height: auto;
        max-height: 50px;

        ion-list 
        {
			display: inline-flex;
			position: relative;
			min-width: 100%;
			padding: 0;
			height: 50px;
            .item
            {
				display: inline-block;
                border: none !important;
            }
            
        }
    }

    .center-scroll-panel
    {
        margin-top: 40px;
        margin-left: 16px !important;
        width: calc(100% - 32px) !important;
    }
}

@media screen and (min-width: 1730px) {
    .center-scroll-panel
    {
		max-width: 1157px;
        margin: auto !important;
    }
}