@import "~@ng-select/ng-select/themes/default.theme.css";
.ng-select{
    width: 100%;
}

//container do input
.ng-select .ng-select-container{
		border-color: #999;
		border-radius: 7px;
		min-height: 30px!important;
		height: 30px!important;
		box-shadow: none!important;
		.ng-value-container {
			padding-left: 15px;
		}
	}

//input
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{
	padding-left: 15px;
}

//item selecionado no dropdown
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
	background-color: rgba(var(--ion-color-tertiary-rgb), 1);
    color: #fff;

	// label do item selecionado no dropdown
	.ng-option-label {
		font-weight: 400;
	}
}

//input com focus no dropdown
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{
	background-color: rgba(var(--ion-color-tertiary-rgb), 0.1);
}

//quando input está disabled
.ng-select.ng-select-disabled>.ng-select-container {
    background-color: initial;
}

.ng-dropdown-panel.ng-select-bottom {
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
}

//quando item tem focus e não tem dropdown aberto
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
	border-color: #999;
}


//quando input está com focus ou com dropdown aberto e tem class highlight-focus
.ng-select.highlight-focus.ng-select-opened.ng-select-bottom>.ng-select-container,
.ng-select.highlight-focus.ng-select-focused.ng-select-bottom>.ng-select-container,
.ng-select.highlight-focus.ng-select-focused:not(.ng-select-opened)>.ng-select-container
{
	border-width: 2px;
    border-color: #000;
}

//elementos pais do componente
.ng-select-item{
	overflow: visible;
}

