ion-header {
	
	//REMOVE BORDER DO TEMPLATE ANDROID *-md
	&.header-md:after 
	{
	 	background-image: none!important;
	}
	// &.with-shadow
	// {

	// 	// &.header-md:after 
	// 	// {
	// 	// 	background: var(--color-light-gradient) !important;
	// 	// 	height: 16px;
	// 	// 	bottom: -16px;
	// 	// }
	// 	// ion-toolbar
	// 	// {
	// 	// 	border-bottom: none !important;
	// 	// }

	// }

	&.page-header
	{
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	ion-toolbar {
		--ion-toolbar-background-color: var(--ion-color-light);

		--background: var(--ion-color-light);

		--border-color: var(--ion-toolbar-border-color, var(--ion-border-color,rgba(0,0,0,0.1)));

		ion-title {
			font-size: var(--ion-header-size);
		}
		// logo-project
		// {
		// 	padding: 0!important;
		// }
		// ion-buttons {
		// 	ion-button {
		// 		// &[fill="solid"] {
		// 		// 	--background: var(--color-primary-gradient)!important;
		// 		// 	--border-color: transparent;

		// 		// 	--padding-start: 16px!important; 	//var(--ion-padding)!important;
		// 		// 	--padding-end: 16px!important;		//var(--ion-padding)!important;
		// 		// 	--border-width: 0px;

		// 		// 	--background: var(--color-primary-gradient)!important;
		// 		// 	--button-background-activated: var(--color-primary-gradient)!important;
		// 		// 	--background-focused: var(--color-primary-gradient)!important;

		// 		// 	--color: var(--ion-color-light);

		// 		// 	&.button-clear:hover .button-native {
		// 		// 		--background: var(--color-primary-gradient)!important;
		// 		// 		background: var(--color-primary-gradient)!important;
		// 		// 	}

		// 		// 	&.activated {
		// 		// 		--background: var(--color-primary-gradient)!important;
		// 		// 	}
		// 		// }
		// 	}
		// }
	}
}
	