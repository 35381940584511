ion-popover
{
	&.custom-popover
	{

		&::part(content)
		{
			background: var(--ion-color-light) !important;
			border-radius: 15px;
			width: 300px;
			margin-left: -19px;
			box-shadow: 0px 0px 12px 0px var(--ion-color-medium-shade);
			border:0;

			.popover-viewport
			{
				box-shadow: none !important;
			}
	
		}

		&::part(backdrop)
		{
			opacity: 0.3 !important;
		}

		
		&::part(arrow)
		{
			z-index: 11;
			margin-left: 25px;
			--background: var(--ion-color-light) !important;
		}

		ion-grid
		{
			padding: 16px !important;

			ion-row
			{
				padding-bottom: 0 !important;
			}

			h3 
			{
				font-size: 1rem !important;
				margin-top:10px
			}
		}

		custom-input-file
		{
			ion-button
			{
				margin-top: 55px !important;
			}
		}

		&.small-width
		{
			&::part(content)
			{
				max-height: 225px!important;
				height: 100%!important;
				// overflow: initial;
			}
		}

		&.quality-militant-indicator
		{
			&::part(content)
			{
				width: 100%;
				padding: 0px 0 20px;
				max-width: 480px;
				height: auto;
				max-height: calc(100vh - 200px);
			}
		}

		&.recovery
		{
			&::part(content)
			{
				max-width: 350px!important;
				width: 100%!important;
				overflow: initial;
			}
		}
		
		&.send
		{
			&::part(arrow)
			{
				margin-left: 45px;
			}
			&::part(content)
			{
				width: 370px;
				overflow: initial;
			}
		}

		&.small-left
		{
			&::part(content) 
			{
				width: 170px;
				margin-left: 16px;

				ion-list
				{
					padding: 0 8px;

					ion-item
					{
						--inner-padding-end: 0px;
						ion-icon
						{
							font-size: 3rem;
							margin-right: 30px;
							margin-left: 0px;
						}
					}

					ion-button
					{
						margin-left: 19px;
					}

				}
			}
		}
		
		&.militant-status
		{
			&::part(content) {
				width: 380px;

			}
		}

		&.default-arrow
		{
			&::part(arrow)
			{
				margin-left: 0px;
			}
		}

		&.no-arrow
		{
			&::part(arrow)
			{
				display: none;
			}
		}


		&.reject-actions
		{
			&::part(content)
			{
				width: 386px;
			}
		}
		
		&.change-document
		{
			&::part(content)
			{
				width: 330px;
			}
		}

		&.auto-height
		{
			&::part(content)
			{
				height: auto !important;
			}
		}

		&.custom-calendar-date-picker
		{
			&::part(content) {
				max-width: 366px!important;
				width: 100%!important;
				border:0;
			}

			ion-grid {
				padding: 5px !important;
			}
		}
		
	}
}