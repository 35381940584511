ion-item-divider {
	border: none;
}

ion-note {
	font-size: calc(var(--font-size-default) - 2px);
}

ion-item {

	outline: none;

	--border-color: transparent;
	--border-width: 0 0 0 0;
	--background: transparent;

	--highlight-height: 0;
	--highlight-color-focused: transparent;
	--highlight-color-valid: transparent;
	--highlight-color-invalid: transparent;
	
	&.dynamic-field,
	&.form-input
	{
		--highlight-color-focused: none!important;
		--background-hover: none !important;
		--background-focused: none!important;
		--button-background-activated: none!important;
		--background: none !important;
		--color-activated: none !important;
		--transition: none !important;
		--ripple-color: transparent !important;
		transition: none !important;
		background: none!important;

		ion-label
		{
			font-size: .85rem !important;
    		color: var(--color-text-grey)!important;
		}
	}

	&.item-has-focus
	{
		--full-highlight-height: 0!important;
		--inset-highlight-height: 0!important;
	}

	ionic-selectable,
	ion-select 
	{

		border: 1px solid #999;

		border-radius: var(--ion-half-padding);

		min-width: 200px !important;

		--padding-top: var(--ion-half-padding);
		--inner-padding-end: 0px;
		--padding-bottom:var(--ion-half-padding);
		&::part(icon){
			transform: none;
		}
	}

	ion-select {
		height: 30px;
		--padding-start: 16px;
		--padding-end: 10px;
		--padding-top: 4px;
		--padding-bottom: 4px;
		border-radius: 7px;
		padding: 5px 16px;
		line-height: initial !important;
	}

	ionic-selectable {
		//padding-top: var(--ion-half-padding);
		margin-top: var(--ion-half-padding);
		padding: 8px 16px 8px 16px !important;
		line-height: initial !important;
		
		--show-full-highlight: 0;

		.ionic-selectable-inner
		{
			margin-top: -4px !important;
		}
		.ionic-selectable-icon
		{
			margin-top:1px !important;
		}

		&.ionic-selectable-has-placeholder,
		&.has-placeholder  
		{
			.ionic-selectable-inner
			{
				margin-top: -16px !important;
			}
		}
		&[without-margin]
		{
			.ionic-selectable-inner
			{
				margin-top: -3px !important;
			}
			.ionic-selectable-icon
			{
				margin-top: 0px !important;
			}
		}
		
	}


	&.no-line,
	&.no-lines 
	{
		--show-full-highlight: 0;
	}

}


ion-segment
{
	border-radius: 7px;
	border:1px solid var(--ion-color-tertiary);
	overflow: hidden;
	height: 30px !important;
	margin: 8px 0px 0 -16px;
	// max-width: 220px;
	// float: right;
	
	ion-segment-button
	{
		// --button-background-activated:var(--color-tertiary-gradient-rgb);
		--indicator-color:var(--color-tertiary-gradient-rgb);
		--background-hover:var(--color-tertiary-gradient-rgb);
		
		// --border-style:solid;
		// --border-width:1px;
		// --border-color:var(--ion-color-tertiary);
		font-size: 1rem;
		font-weight: normal;
		--color:var(--ion-color-medium-tint);
		--color-checked:var(--ion-color-light);
		
		margin: -1px -2px 0px;

		// &:hover{
		// 	--color:var(--ion-color-light) !important;
		// }

		// --color-activated:var(--ion-color-light);
		// --indicator-color:var(--ion-color-light);
		// --indicator-color:var(--ion-color-light);
		
		height: 30px !important;
		min-height: 30px !important;
		--font-size-default:0.7rem!important;
		text-transform: capitalize!important;

		ion-label
		{
			width: 100%;
		}
	}


	&.clean
	{
		border:none;
		ion-segment-button
		{
			--background-hover:transparent;
			--background-checked:transparent;
			--color-checked:var(--ion-color-tertiary);
			--font-size-default:1rem!important;

			&:hover{
				--color:var(--ion-color-tertiary) !important;
			}

		
		}
	}
}