ion-button {

	height: 30px !important;
    text-transform: none;
	--ripple-color: var(--color-tertiary-gradient);

	&.button-disabled
	{
		opacity: 0.7;
	}


	&[fill="clear"] {

		text-transform: capitalize;

		--border-radius: 64px!important;

		
	}

	&[shape="round"] {
		--border-radius: 7px !important;
	}

	&[fill="solid"] {
		--background: var(--color-tertiary-gradient)!important;
		--button-background-activated: var(--color-tertiary-gradient)!important;
		--background-focused: var(--color-tertiary-gradient)!important;
		--background-hover: var(--color-tertiary-gradient-rgb)!important;

		--color: var(--ion-color-light) !important;
		
		--padding-start: 16px!important;
		--padding-end: 16px!important;

		--border-width: 0;

		--box-shadow: none;
		--transition: all .1s ease-in-out;

		// height: 32px !important;

		&:not([text-capitalize], [text-uppercase], [text-uppercase])
		{
			text-transform: capitalize;
		}
	}

	ion-icon
	{
		font-size: 1rem;
	}	
	
	&.overflow-circle
	{
		margin-left: 47px!important;
		position: absolute!important;
		z-index: 99!important;
		margin-top: 20px!important;
		width: 23px!important;
		height: 23px !important;
		--padding-top: 1px!important;
		--padding-start: 10px !important;
		--padding-end: 0px !important;
		--border-radius: 90px !important;
		--background-hover: var(--color-tertiary-gradient)!important;
	}

}
	