ion-input {
	text-overflow: ellipsis;
	--padding-bottom: 3px !important;
	--padding-top: 3px !important;
	height: 30px !important;
	caret-color: var(--ion-color-primary);
}

ion-toggle
{
	--background: var(--ion-color-light-tint);
	--background-checked: var(--ion-color-tertiary);
	--handle-background-checked: var(--ion-color-light);
	--handle-background: var(--ion-color-medium-shade);
}

ion-radio
{
	--color: var(--ion-color-medium-shade) !important;
	--color-checked: var(--ion-color-tertiary);

	--border-radius: 4px;
  	--inner-border-radius: 4px;

	margin-inline-end: 16px;
	margin-top: 6px;
    margin-bottom: 5px;
}

ion-radio-group
{
	ion-item
	{
		border-radius: 7px;
	}
}

ion-textarea
{
	border-radius: 7px;
	border: 1px solid var(--ion-color-medium-shade) !important;
	// margin-top: 16px;
	// margin-bottom: 16px;
    --padding-start: 12px !important;

}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}
input[type=number] {
    -moz-appearance:textfield;
}