.custom-modal-card
{
	&::part(content) {
		--height: 70%;
		--width: 85%;
		max-width: 935px;
	}

	&.modal-small
	{
		&::part(content) 
		{
			--height: 520px;
			--width: 600px;
		}

		&.extra
		{
			--height: 80%;
			max-height: 680px;
		}
	}

	@media screen and (max-width: 768px) and (orientation: portrait)
	{
		&::part(content)
		{
			--height: 100%;
			--width: 100%;
			max-width: 100%;
			border-radius: 0px !important;
		}
	}

	@media screen and (min-width: 1200px) and (min-height: 800px) 
	{
		&::part(content)
		{
			max-height: 640px;
		}
	}
}

.custom-modal
{
	// ion-footer {
	// 	height: 68px;
	// }

	
	&::part(content) 
	{
		max-height: 90%;
	}

	@media only screen and (max-height: 740px) and (max-width: 768px) 
	{
		&::part(content) 
		{
			--height: 100% !important;
			--width: 100% !important;
			border-radius: 0 !important;
			max-height: 100% !important;
		}
	}

	&.alert-small
	{
		&::part(content) 
		{
			max-width: 300px;
			max-height: 300px;
		}
	
	}
	
}

ion-modal {
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-track {
		background: var(--ion-color-light-tint);
	}
	::-webkit-scrollbar-thumb {
		border-radius: 0;
		background: var(--color-scrollbar-gradient);
		border: none;
	}
}

ion-modal::part(content) 
{
	--width: 720px;
	--height: 740px;
	
	border-radius: 10px !important;

	background: white;
}

.modal-extra-large {

	&::part(content) 
	{
		--width: 1184px;
		--height: 800px;
		max-width: 95%;
	}

	@media only screen and (max-height: 800px), screen and (max-width: 1184px) {
		&::part(content) 
		{
			--width: 100%;
			--height: 100%;
			max-width: 100%;
			max-height: 100% !important;
			border-radius: 0 !important;
		}
	}
}

.modal-large {

	&::part(content) 
	{
		--width: 1024px;
		--height: 95%;
		max-height: 750px;
		// height: 670px;
	}

	@media only screen and (max-height: 750px), screen and (max-width: 1024px) {
		&::part(content) 
		{
			--width: 100%;
			--height: 100%;
			max-height: 100% !important;
			border-radius: 0!important;
		}
	}
}

.modal-small {

	&::part(content) 
	{
		--width: 460px;
		--height: 540px;
	}

	@media only screen and (max-height: 460px), screen and (max-width: 540px) {
		&::part(content) 
		{
			--width: 100%;
			--height: 100%;
			
			border-radius: 0!important;
		}
	}

	&.extra
	{
		&::part(content) 
		{
			--width: 768px;
			--height: 640px;
		}
		
		@media only screen  and (max-width: 768px) {
			&::part(content) 
			{
				--width: 100%;
				--height: 100%;
				
				border-radius: 0!important;
			}
		}
	}
}


.modal-extra-small {
	&::part(content) 
	{
		--width: 460px;
		--height: 310px;
	}

	&.modal-generic-select
	{
		&::part(content) 
		{
			--height: 230px;
		}
	}

	@media only screen and (max-height: 460px), screen and (max-width: 540px) {
		&::part(content) 
		{
			--width: 100%;
			--height: 100%;
			
			border-radius: 0!important;
		}
	}
}

.modal-login-small {
	&::part(content) 
	{
		--width: 460px;
		--height: 510px;
	}

	@media only screen and (max-height: 460px), screen and (max-width: 540px) {
		&::part(content) 
		{
			--width: 100%;
			--height: 100%;
			
			border-radius: 0!important;
		}
	}
}


.ionic-selectable-modal
{
	ion-toolbar
	{
		.button 
		{
			text-transform: capitalize !important;
			// color: var(--ion-color-danger);
		}

		.searchbar-input
		{
			box-shadow: none;
			border-bottom: 1px solid var(--ion-color-medium);
		}
	}

	ion-content {
		.ionic-selectable-item {
			ion-icon {
				--ion-color-base: var(--ion-color-tertiary)!important;
				font-size: 24px;
				margin-right: 24px;
			}
		}
	}

	ion-footer
	{
		ion-row {
			> ion-col {
				display: flex;
    			justify-content: flex-end;

				ion-button
				{
					--color: var(--ion-color-primary);
					--background: transparent;
					--box-shadow: none;
					max-width: 150px;
				}
			}
		}
		
	}
}

.custom-small-ionic-selectable
{
	&::part(content)
	{
		--width: 100%;
		--height: 100%;
		max-width: 100%;
		max-height: 100%;
		border-radius: 0;

	}
	
	@media only screen and (max-height: 460px), screen and (min-width: 540px) 
	{
		&::part(content)
		{
			border-radius: 10px;
			max-width: 400px;
			max-height: 540px;
		}
	}
}