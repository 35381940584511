[half-padding] {
	padding: var(--ion-half-padding);
}

[half-padding-top] {
	padding-top: var(--ion-half-padding);
}

[half-padding-bottom] {
	padding-bottom: var(--ion-half-padding);
}

[half-padding-start] {
	padding-left: var(--ion-half-padding);
}

[half-padding-end] {
	padding-right: var(--ion-half-padding);
}

[half-padding-vertical] {
	padding: var(--ion-half-padding) 0;
}

[half-padding-horizontal] {
	padding: 0 var(--ion-half-padding);
}

[half-margin] {
	margin: var(--ion-half-margin);
}

[half-margin-top] {
	margin-top: var(--ion-half-margin);
}

[half-margin-bottom] {
	margin-bottom: var(--ion-half-margin);
}

[half-margin-start] {
	margin-left: var(--ion-half-margin);
}

[half-margin-end] {
	margin-right: var(--ion-half-margin);
}

[half-margin-vertical] {
	margin: var(--ion-half-margin) 0;
}

[half-padding-horizontal] {
	margin: 0 var(--ion-half-margin);
}

[no-padding-start] {
	padding-left: 0;
}

[no-padding-end] {
	padding-right: 0;
}

[no-padding-vertical] {
	padding-bottom: 0;
	padding-top: 0;
}

[no-padding-horizontal] {
	padding-left: 0;
	padding-right: 0;
}

.float-left,
[float-left] {
	float: left;
}

.float-right
{	
	float: right;
}

.ion-text-nowrap 
{
	text-overflow: ellipsis;
	overflow: hidden;
}