// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

//IMPORT MapBox CSS
@import "~mapbox-gl/dist/mapbox-gl.css";

@import "theme/layout/layout";

//OVERRIDES AND CUSTOM CSS
@import 'theme/overrideionic/overrideionic';
@import 'theme/maps/map';
