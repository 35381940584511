.map 
{
    position: absolute;
    top: 0;
	left: 0;
	height: 100%;
    width: 100%;
}

/* Marker tweaks */
.mapboxgl-popup-close-button {
	display: none;
}

.mapboxgl-popup-content {
	padding: 0;
	width: 220px;
}

.mapboxgl-popup-content-wrapper {
	padding: 1%;
}
  
.mapboxgl-popup-content {
	h3 {
		background: var(--ion-color-primary);
		color: #fff;
		margin: 0;
		display: block;
		padding: 10px;
		border-radius: 4px 4px 0 0;
		font-weight: 700;
		margin-top: -15px;
	}
	.popup-content {
		margin: 0;
		display: block;
		padding: 10px;
		font-weight: 200;
		font-size: 0.87em;

		p {
			margin: 0;
			padding: 0;
		}

		ion-icon {
			padding: 0;
			font-size: 2em;
			float: right;
			cursor: pointer;
		}
	}
}

.mapboxgl-popup {
	padding-bottom: 20px;
  }

.mapboxgl-popup-content {
	ion-icon {
		padding: 10px;
	}
}

.mapboxgl-container {
	ion-icon {
		cursor: pointer;
	}
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
	margin-top: 15px;
}
  