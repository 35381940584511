.table-grid {
	padding: 0;

	.table-grid-row-header 
	{
		position: sticky;
		z-index: 9999999;
		top: -1px;
		left: 0;
	
		--background: var(--ion-color-light);
		background: var(--ion-color-light);

		ion-col 
		{
			font-weight: bold;
		}
	}

	.table-grid-row-list {

		border-bottom: 1px solid var(--border-color);

		&:nth-child(2n)
		{
			// background-color: var(--ion-color-step-600,#f5f5f5);
		}

		ion-col 
		{
	

			// height: 3.43em;

			display: flex;
			align-items: center;

			&[float-right] {
				justify-content: flex-end;
			}

			&[float-left] {
				justify-content: flex-start;
			}
		}
	}
}