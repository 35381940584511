ion-card
{
	// border-radius: 0 !important;
	// margin-bottom: var(--ion-margin, 0px) !important;
	// --background: var(--ion-color-light) !important;
	// border-top: 10px solid rgba(var(--ion-color-medium-rgb), .2);
	// border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), .2);

	&.rounded-card
	{
		border-radius: 10px;
		margin-bottom: 40px;
		box-shadow:  0 0 50px 0 rgba(0, 0, 0, 0.12) !important;

		ion-card-header {
			
			padding-top: 5px;
			padding-bottom: 5px;
			margin-bottom: 15px;
			border-bottom: 1px solid var(--ion-color-light-shade);

			> ion-grid > ion-row {
				min-height: 38px;
			}

			ion-title{
				font-weight: 700;
				font-size: 1rem!important;
				color: var(--ion-color-dark);
				margin-left: -3px;
			}

			ion-button{
				font-size: 12px;
			}
		}

		ion-card-content {
			
		}

		&.card-table {
			ion-card-header {

				font-size: 1em;

				ion-row {
					margin-bottom: var(--ion-half-margin);
				}

				ion-col {
					font-weight: bold;
					color: var(--color-text-dark);
				}
			}

			ion-card-content {

			}
		}
		
	}

	&.structure-card {
		ion-card-header {
			font-weight: bold;
			font-size: 1.2em;

			color: var(--color-text-dark);

			border-bottom: none;
			padding: 16px !important;
			margin: 0 !important;
		}
	}
}

