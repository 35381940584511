ion-searchbar {

	&.custom
	{

		// --background: var(--ion-color-light);
		.searchbar-input-container
		{
			// border: 1px solid #999;
			// border-radius: 7px;
			height: 30px !important;
		}
		.searchbar-input {
			border-radius: 7px !important;
			border: none !important;

			// border-radius: 20px!important;
		}

		&.small
		{
			.searchbar-input-container
			{
				.searchbar-search-icon
				{
					top: 5px !important;
				}
			}

			.searchbar-input
			{
				height: 30px !important;
				box-shadow: none !important;
				border: 1px solid #999 !important;
			}
			
		}
	}
}

