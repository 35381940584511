// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@import url('https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700,900');

// @font-face {
// 	font-family: 'Myriad Pro Regular';
// 	font-style: normal;
// 	font-weight: normal;
// 	src: local('Myriad Pro Regular'), url('../../assets/font/MYRIADPRO-REGULAR.woff') format('woff');
// }

/** Ionic CSS Variables **/
:root {

	// --ion-font-family: "Myriad Pro Regular", "Myriad Pro", Myriad, sans-serif;
	--ion-font-family: "Maven Pro", "Roboto", sans-serif;
	--font-size-default: 14px;

	//Grid values
	--ion-grid-width-xs: 100%; 	 //100%;
	--ion-grid-width-sm: 768px;  //540px;
	--ion-grid-width-md: 1024px; //720px;
	--ion-grid-width-lg: 1280px; //960px;
	--ion-grid-width-xl: 1440px; //1140px;

	/** primary **/
	--ion-color-primary: #313134;
	--ion-color-primary-rgb: 0,96,156;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #333333;
	--ion-color-primary-gradient-start: #313134;
	--ion-color-primary-gradient-end: #333333;

	/** secondary **/
	--ion-color-secondary: #333333;
	--ion-color-secondary-rgb: 74,130,165;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #0bb8cc;
	--ion-color-secondary-tint: #24d6ea;
	--ion-color-secondary-gradient-start: #333333;
	--ion-color-secondary-gradient-end: #24d6ea;

	/** tertiary **/
	--ion-color-tertiary: #dc0d15;
	--ion-color-tertiary-rgb: 220, 13, 21;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #ca660e;
	--ion-color-tertiary-tint: #dc0d15;

	--ion-color-fourth: #e88b3b;
	--ion-color-fourth-rgb: 232, 139, 59;
	--ion-color-fourth-contrast: #ffffff;
	--ion-color-fourth-contrast-rgb: 255,255,255;
	--ion-color-fourth-shade: #d38038;
	--ion-color-fourth-tint: #e88b3b;
	

	--ion-color-fifth: #d3050c;
	--ion-color-fifth-rgb: 255, 69, 75;
	--ion-color-fifth-contrast: #ffffff;
	--ion-color-fifth-contrast-rgb: 255,255,255;
	--ion-color-fifth-shade: #d38038;
	--ion-color-fifth-tint: #d3050c;
	
	--ion-color-sixth : #ff454b;
	--ion-color-sixth-rgb: 211, 5, 12;
	--ion-color-sixth-contrast: #ffffff;
	--ion-color-sixth-contrast-rgb: 255,255,255;
	--ion-color-sixth-shade: #d38038;
	--ion-color-sixth-tint: #ff454b;

	/** success **/
	--ion-color-success: #56b475;
	--ion-color-success-rgb: 16,220,96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;
	--ion-color-success-gradient-start: #61dc83;
	--ion-color-success-gradient-end: #09bc3a;

	/** warning **/
	// --ion-color-warning: #ffce00;
	--ion-color-warning: #ebbc2e;
	--ion-color-warning-rgb: 255,206,0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255,255,255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;
	--ion-color-warning-gradient-start: #ffc100;
	--ion-color-warning-gradient-end: #f99e00;

	/** danger **/
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245,61,61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;
	--ion-color-danger-gradient-start: #ff454b;
	--ion-color-danger-gradient-end: #d3050c;

	/** dark **/
	--ion-color-dark: #323233;
	--ion-color-dark-rgb: 34,34,34;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #282a2e;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152,154,162;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #999999;
	--ion-color-medium-tint: #a2a4ab;

	/** light **/
	--ion-color-light: #ffffff;;
	--ion-color-light-rgb: 244,244,244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #e6e6e6;
	--ion-color-light-tint: #eeeef0;

	/**Text Colors**/
	--color-text-dark: #1a1a1a;
	--color-text-grey: #4d4d4d;
	--color-text-alert-error: #ba3737;
	--color-text-alert-success: #97ba37;

	/** Shadow Color**/
	--color-shadow: #222428;

	/** Novo set de cores **/
	--background-headers: #FAFAFA;
	--background-cards: #ffffff;
	--border-color: #efefef;

	// header on tables
	--row-header-color: #f5f5f5;

	/** Gradients **/
	--color-primary-gradient: linear-gradient(to right, var(--ion-color-primary-gradient-start), var(--ion-color-primary-gradient-end));
	--color-secundary-gradient: linear-gradient(to right, var(--ion-color-secundary-gradient-start), var(--ion-color-secundary-gradient-end));
	--color-tertiary-gradient: linear-gradient(to right, var(--ion-color-fifth), var(--ion-color-sixth));
	--color-tertiary-gradient-rgb: linear-gradient(to right, rgba(var(--ion-color-fifth-rgb), .8), rgba(var(--ion-color-sixth-rgb),.8));
	--color-fourth-gradient: linear-gradient(to right, var(--ion-color-fifth), var(--ion-color-sixth));
	--color-dark-gradient: linear-gradient(to right, var(--ion-color-dark), var(--ion-color-medium));

	
	--color-success-gradient: linear-gradient(to right, var(--ion-color-success-gradient-start), var(--ion-color-success-gradient-end));
	--color-warning-gradient: linear-gradient(to right, var(--ion-color-warning-gradient-start), var(--ion-color-warning-gradient-end));
	--color-danger-gradient: linear-gradient(to right, var(--ion-color-danger-gradient-start), var(--ion-color-danger-gradient-end));

	--color-light-gradient: linear-gradient(to bottom, var(--ion-color-light), rgba(0,0,0, 0) 100%);

	--color-inactive-card-gradient: linear-gradient(to right, var(--ion-color-dark), var(--ion-color-medium));

	--color-active-card-gradient: linear-gradient(to right, #f89225, #ff5a00);
	--color-light-gradient-menu: linear-gradient(to right, var(--ion-color-light), rgb(250, 246, 246) 100%);

	--color-scrollbar-gradient: linear-gradient(to bottom, #ff454b, #d3050c);
	--color-scrollbar-gradient-light: linear-gradient(to bottom,  var(--ion-color-light-tint), var(--ion-color-medium-tint));

	/** IONIC OVERRIDES **/
	--item-ios-detail-push-show: false;

	/** Espacamentos */
	--ion-padding: 16px;
	--ion-margin: 16px;

	--ion-half-padding: calc(var(--ion-padding) / 2);
	--ion-half-margin: calc(var(--ion-margin) / 2);

	/** Override Variables of ionic **/
	--ion-header-size: 20px;

	--custom-menu-size: 18em;
	
	background: var(--ion-color-light);

	// --grid-breakpoint-xs: 0;
    // --grid-breakpoint-sm: 400px;
    --grid-breakpoint-md: 800px !important;
    // --grid-breakpoint-lg: 992px;
    // --grid-breakpoint-xl: 1200px;
}