ion-alert
{
	&.custom-alert
	{
		.alert-wrapper
		{			
			border-radius: 20px;

			.alert-button
			{
				text-transform: none;
				&.secondary
				{
					text-transform: lowercase;
				}
			}
		}

		&.small-height
		{
			.alert-wrapper
			{
				--height: 410px !important;

				.alert-checkbox-group
				{
					max-height: 300px !important;
				}
			}
		}

	}

	&.medium
	{
		.alert-wrapper
		{
			width: 100%;
		
			max-width: 400px;
			--height: 400px;

			.alert-radio-group,
			.alert-checkbox-group
			{
				height: 100%;
				max-height: 100%;
			}
		}

	}

	&.height-auto
	{
		.alert-wrapper
		{
			
			--height: auto;
		}
	}

	&.large
	{
		.alert-wrapper
		{
			width: 100%;
			max-width: 450px;
			--height: 480px;

			.alert-radio-group,
			.alert-checkbox-group
			{
				height: 100%;
				max-height: 100%;
			}
		}

		&.ultra
		{
			.alert-wrapper
			{
				max-width: 600px;
			}
		}
		&.extra
		{
			.alert-wrapper
			{
				--height: 575px;
				.alert-checkbox-group
				{
					max-height: 460px;
				}
			}
		}

		&.small-height
		{
			.alert-wrapper
			{
				--height: 290px !important;
			}
		}
	}

	&.large-height
	{
		.alert-wrapper
		{
			width: 100%;
			max-width: 310px;
			height: 100%;
			max-height: 575px;

			.alert-checkbox-group
			{
				height: 100%;
				max-height: 100%;
			}
		}
	}
}