
//
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-border-radius: 50px;
	border-radius: 50px;

	-webkit-transition: background-color 9999s ease-out;
	-webkit-text-fill-color: black !important;
}

.line-bottom {
	border-bottom: 1px solid var(--border-color)!important;
}

.dot {
	height: 8px;
	width: 8px;
	background-color: #f26524!important;
	border-radius: 50%;
	display: inline-block;
}

.pointer{
	cursor: pointer!important;
}

.w100
{
	width: 100%;
}