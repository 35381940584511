ion-label 
{
	&[position='stacked'] {
		font-weight: bold;
		transform: inherit !important;
		color: var(--ion-color-dark) !important;
	}

	//color: var(--color-text-grey);

	//--color: var(--color-text-grey);

	font-size: var(--font-size-default)!important;

	&[required]:after
	{
		content: '*';
		color: var(--ion-color-danger);
	}
}