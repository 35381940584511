ion-toast
{
    --background: var(--ion-color-tertiary);


    &.error
    {
        --background: var(--ion-color-danger);
    }

    &.warnning
    {
        --background: var(--ion-color-warning-gradient-end);
    }
}