// --background		Background of the menu
// --height			Height of the menu
// --max-height		Maximum height of the menu
// --max-width		Maximum width of the menu
// --min-height		Minimum height of the menu
// --min-width		Minimum width of the menu
// --width			Width of the menu

ion-menu {
    //Controla a dimensão do sidemenu quando visto em mobile
    --width: 18em;

    --background: var(--color-primary-gradient);

    color: var(--ion-color-light);
    --border: 0 !important;

    ion-content {
        --background: transparent;
    }
}

//Controla a dimensão maxima do sidemenu quando expandido em ecrã "grandes"
// .split-pane-visible>.split-pane-side {
//     max-width: var(--custom-menu-size) !important;
//     min-width: var(--custom-menu-size) !important;
// }


@media screen and (min-width: 1200px) {
    .split-pane-visible>.split-pane-side {
        max-width: var(--custom-menu-size) !important;
        min-width: var(--custom-menu-size) !important;
        transition: all .2s ease-in;
    }
}