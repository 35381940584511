.header-content {
	background-color: var(--color-background-headers-content);
	border-bottom: 5px solid var(--color-border);

	&.sticky {
		position: -webkit-sticky; 
		position: sticky;
		z-index:99;
		top: 0;
	}
}

.sub-header-content {
	border-bottom: 5px solid var(--color-border);
}

.title-content-entry {
	ion-label {
		color: var(--ion-color-primary);
		border-bottom: 1px solid var(--color-border);
		padding: 16px 16px 8px 16px;
		width: 100%;
	}

	border-bottom: 1px solid var(--color-border);
}

.content-entry {
	padding: 16px 16px 8px 16px;

	&:last-child {
		padding-bottom: 16px;
	}
}

.scrollable-fixed-container {
	height: 100%;
}

.scrollable-content {
	overflow-y: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;

	padding-top: 0;
	
	ion-list {
		border-bottom: 5px solid var(--color-border);
	}
}

.fixed-content {
	height: 100%;
}

@media only screen and (max-width: 992px) {

	.scrollable-fixed-container {
		//display: flex;
		//flex-direction: row-reverse;
	}

    .fixed-content {
		height: 40%;
	}

	.scrollable-content {
		overflow-y: visible;
		height: auto;
		
		-webkit-overflow-scrolling: none;
		-ms-overflow-style: none;
	}
}