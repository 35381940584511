//FONT SOURCE CODE
//https://www.grzegorowski.com/ionic-3-conditional-visibility-of-elements/
// The minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries
$screen-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$breakpoints-keys: map-keys($screen-breakpoints);

$breakpoints-values: map-values($screen-breakpoints);

@for $i from 1 to length($breakpoints-keys)+1 {
//   @if $i < length($breakpoints-keys) {
//     [hidden-#{nth($breakpoints-keys, $i)}] {
//       @media (min-width: nth($breakpoints-values, $i)) and (max-width: #{nth($breakpoints-values, $i+1) - 1}) {
//         display: none !important;
//       }
//     }
//   } @else {
    [hide-on-#{nth($breakpoints-keys, $i)}] {
      @media (max-width: nth($breakpoints-values, $i)) {
        display: none !important;
      }
    }
  //}
}