
body {
	font-size: var(--font-size-default);
}

.header-text {
	color: var(--color-text-dark)!important;
	font-weight: bold;
	padding-bottom: 0;
}

.description-text {
	color: var(--color-text-grey)!important;
	padding-top: 0;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Old versions of Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Opera and Firefox */
  }
  