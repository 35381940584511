form {
	ion-select {
		margin-top: var(--ion-half-margin);
		padding-top: var(--ion-half-padding);
		padding-bottom: var(--ion-half-padding);
		padding-left: var(--ion-half-padding);
		padding-right: var(--ion-half-padding);
	}

	.sc-ion-card-md-s img {
		width: initial;
	}


}
.form-full
{
	.dynamic-field
	{
		max-width: 100% !important;
		width: 100% !important;
	}
}
.content-center-form
{
	&.full-size
	{
		max-width: 100%;
	}
	&.three-quarter-size
	{
		max-width: 95%;

	}
    // left: 11%;

	margin: auto;
	position: relative;
}

.dynamic-field
{
	max-width: 60%;
	margin-top: 20px;
	min-width: 100px;

	ion-label 
	{
		margin-bottom:10px!important;
	}

	ion-button
	{
			margin-top:30px;
	}

	ion-input, 
	ion-datetime, 
	ionic-selectable 
	{
		input, 
		&input, 
		.native-input 
		{
			--padding-start: 15px;
			--padding-end: 15px;

			text-overflow: ellipsis;
			overflow: hidden;
			display: block;
			white-space: nowrap;
			//background: #e40505;
		}
		.ionic-selectable-icon
		{
			margin-top: -2px;
			margin-right: -6px;
		}
		
		height: 30px;
		border:1px solid #999;
		border-radius: 7px;
	}

	ion-datetime
	{
		padding: 9px 16px;
	}
	
	ionic-selectable 
	{
		margin-top: 0px;
	}

	b.required {
		color:#e40505!important;
	}
}

.dynamic-field
{
		
	&.full-input
	{
		max-width: 100%!important;
	}
	&.small-input
	{
		max-width: 25%!important;
		min-width: 220px;
	}
}

.item-md.item-block .item-inner,
.item-md.item-input.input-has-focus .item-inner,
.item-md.item-input.ng-valid.input-has-value:not(.input-has-focus) .item-inner,
.item-md.item-input.ng-invalid.ng-touched:not(.input-has-focus) .item-inner,
.list-md .item-input.input-has-focus:last-child,
.list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):last-child,
.list-md .item-input.ng-invalid.ng-touched:not(.input-has-focus):last-child {
    border-bottom: none !important;
    box-shadow: none!important;
}
.list-md .item-input.input-has-focus:last-child .item-inner,
.list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):last-child .item-inner,
.list-md .item-input.ng-invalid.ng-touched:not(.input-has-focus):last-child .item-inner {
    box-shadow: none !important; 
}

@media screen and (max-width: 992px) 
{
	.dynamic-field,
	.dynamic-field.small-input
	{
		max-width: 100% !important;
		width: 100% !important;
	}
}
@media screen and (min-width: 1730px) 
{
	.content-center-form
	{
	
		&.three-quarter-size
		{
			max-width: 65%;
	
		}
	}
}
