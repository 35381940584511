.align-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.align-center-vertical {
	display: flex;
	align-items: center;
}

.align-center-horizontal {
	display: flex;
	justify-content: center;
}

.align-right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.align-left {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}